<template>
  <div class="page-container">
    <div class="content-wrap">
    <TopNavBar
      v-bind:secondTierLoadingDone="true"/>
    <br>
    <b-container fluid="true">
      <b-row>
        <b-col class="col-0 col-sm-0 col-md-1 col-lg-2"></b-col>
        <b-col class="col-12 col-sm-12 col-md-10 col-lg-8">
          <b-card class = "mt-5">
            <div v-html="commercial" class="mt-1"></div>
             <div class="col-md-12">
        <b-carousel
          id="carousel-fade"
          style="padding: 20px"
          class="card-deck mb-3 mt-5 bg-secondary"
          fade
      controls
        >
               <h2 class="pb-3 text-white">gender app wird genutzt von</h2>
          <b-carousel-slide
          class="d-flex justify-content-around">
            <template v-slot:img>
              <b-card-group>
                <b-card tag="article" img-top style="max-width: 250px; padding: 20px" img-src="/img/client1.png"><b-card-text style="text-align: center;"><a target="_blank" href="https://www.dasletzteauge.li/">Das letzte Auge</a></b-card-text></b-card>
                <b-card tag="article" img-top style="max-width: 250px; padding: 20px" img-src="/img/client2.png"><b-card-text style="text-align: center;"><a target="_blank" href="http://www.praxisdz.ch/index.cfm">Dolezal & Zeller</a></b-card-text></b-card>
                <b-card tag="article" img-top style="max-width: 250px; padding: 20px" img-src="/img/client3.png"><b-card-text style="text-align: center;"><a target="_blank" href="https://www.jungegruene.ch/">Die jungen Grünen</a></b-card-text></b-card>
              </b-card-group>
            </template>
          </b-carousel-slide>
          <b-carousel-slide
          class="d-flex justify-content-around">
            <template v-slot:img>
              <b-card-group>
                <b-card tag="article" img-top style="max-width: 250px; padding: 20px" img-src="/img/client2.png"><b-card-text style="text-align: center;"><a target="_blank" href="http://www.praxisdz.ch/index.cfm">Dolezal & Zeller</a></b-card-text></b-card>
                <b-card tag="article" img-top style="max-width: 250px; padding: 20px" img-src="/img/client3.png" ><b-card-text style="text-align: center;"><a target="_blank" href="https://www.jungegruene.ch/">Die jungen Grünen</a></b-card-text></b-card>
                <b-card tag="article" img-top style="max-width: 250px; padding: 20px"  img-src="/img/client4.png"><b-card-text style="text-align: center;"><a target="_blank" href="https://wydehoefli.ch/">Wohnheim Wydehoefli</a></b-card-text></b-card>
              </b-card-group>
            </template>
          </b-carousel-slide>
          <b-carousel-slide
          class="d-flex justify-content-around">
            <template v-slot:img>
              <b-card-group>
                <b-card tag="article" img-top style="max-width: 250px; padding: 20px" img-src="/img/client3.png"><b-card-text style="text-align: center;"><a target="_blank" href="http://www.praxisdz.ch/index.cfm">Dolezal & Zeller</a></b-card-text></b-card>
                <b-card tag="article" img-top style="max-width: 250px; padding: 20px" img-src="/img/client4.png" ><b-card-text style="text-align: center;"><a target="_blank" href="https://www.jungegruene.ch/">Die jungen Grünen</a></b-card-text></b-card>
                <b-card tag="article" img-top style="max-width: 250px; padding: 20px"  img-src="/img/client5.png"><b-card-text style="text-align: center;"><a target="_blank" href="https://wydehoefli.ch/">Wohnheim Wydehoefli</a></b-card-text></b-card>
              </b-card-group>
            </template>
          </b-carousel-slide>
          <b-carousel-slide
          class="d-flex justify-content-around">
            <template v-slot:img>
              <b-card-group>
                <b-card tag="article" img-top style="max-width: 250px; padding: 20px" img-src="/img/client4.png"><b-card-text style="text-align: center;"><a target="_blank" href="http://www.praxisdz.ch/index.cfm">Dolezal & Zeller</a></b-card-text></b-card>
                <b-card tag="article" img-top style="max-width: 250px; padding: 20px" img-src="/img/client5.png" ><b-card-text style="text-align: center;"><a target="_blank" href="https://www.jungegruene.ch/">Die jungen Grünen</a></b-card-text></b-card>
                <b-card tag="article" img-top style="max-width: 250px; padding: 20px"  img-src="/img/client1.png"><b-card-text style="text-align: center;"><a target="_blank" href="https://wydehoefli.ch/">Wohnheim Wydehoefli</a></b-card-text></b-card>
              </b-card-group>
            </template>
          </b-carousel-slide>
          <b-carousel-slide
          class="d-flex justify-content-around">
            <template v-slot:img>
              <b-card-group>
                <b-card tag="article" img-top style="max-width: 250px; padding: 20px" img-src="/img/client5.png"><b-card-text style="text-align: center;"><a target="_blank" href="http://www.praxisdz.ch/index.cfm">Dolezal & Zeller</a></b-card-text></b-card>
                <b-card tag="article" img-top style="max-width: 250px; padding: 20px" img-src="/img/client1.png" ><b-card-text style="text-align: center;"><a target="_blank" href="https://www.jungegruene.ch/">Die jungen Grünen</a></b-card-text></b-card>
                <b-card tag="article" img-top style="max-width: 250px; padding: 20px"  img-src="/img/client2.png"><b-card-text style="text-align: center;"><a target="_blank" href="https://wydehoefli.ch/">Wohnheim Wydehoefli</a></b-card-text></b-card>
              </b-card-group>
            </template>
          </b-carousel-slide>
        </b-carousel>
<div class="p-2">
   Wenn wir dir oder der gleichstellungsverantwortlichen Person deiner Firma/Organisation eine unverbindliche Offerte zukommen lassen sollen, let us know: <strong><a href="#" class="cryptedmail" data-name="info" data-domain="genderapp" data-tld="org" onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"></a>
   </strong>
</div>
      </div>
          </b-card>

        </b-col>
        <b-col class="col-0 col-sm-0 col-md-1 col-lg-2"></b-col>
      </b-row>
    </b-container>
    </div>
    <GenderAppFooter/>
  </div>
</template>

<script>
// @ is an alias to /src
import TopNavBar from '../components/TopNavBar'
import GenderAppFooter from '../components/Footer'
import { SiteManagerService } from '../services/siteManagerService'

export default {
  name: 'Commercial',
  // insert the following code for vue-meta to work
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          vmid: 'description',
          content: this.description
        },
        {
          property: 'og:title',
          vmid: 'og:title',
          content: this.title
        },
        {
          property: 'og:description',
          vmid: 'og:description',
          content: this.description
        },
        {
          property: 'og:url',
          vmid: 'og:url',
          content: this.canonicalUrl
        },
        {
          name: 'twitter:title',
          vmid: 'twitter:title',
          content: this.title
        },
        {
          name: 'twitter:description',
          vmid: 'twitter:description',
          content: this.description
        }
      ],
      link: [
        { rel: 'canonical', href: this.canonicalUrl }
      ]
    }
  },
  components: {
    GenderAppFooter,
    TopNavBar
  },
  data () {
    return {
      commercial: '',
      title: 'Kommerzielle Nutzung · gender app',
      description: 'Für deine Firma/Organisation hat gender app verschiedene Angebote:',
      canonicalUrl: 'https://genderapp.org/commercial'
    }
  },
  methods: {
    getSiteManager () {
      SiteManagerService.getListViewData('', 'all')
        .then((response) => {
          this.commercial = response.data.results[0].commercial
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  mounted () {
    this.getSiteManager()
  }
}
</script>
